import clsx from "clsx";

export enum CardVariant {
  solid = "solid",
  dashed = "dashed",
  none = "none",
}

interface CardProps {
  children: React.ReactNode;
  className?: string;
  variant?: CardVariant;
  flat?: boolean;
}

export default function Card({
  children,
  className,
  variant = CardVariant.solid,
  flat = false,
  ...props
}: CardProps) {
  return (
    <div
      className={clsx(
        "border border-black p-4",
        {
          "border-solid ": variant === CardVariant.solid,
          "border-dashed ": variant === CardVariant.dashed,
          shadow: !flat && variant === CardVariant.solid,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
