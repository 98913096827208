/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Implement the DateTime<Utc> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: { input: any; output: any; }
  Uuid: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  cadence: ReportCadence;
  confirmed: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Uuid']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  moondipInbox?: Maybe<Scalars['String']['output']>;
  nextReportAt: Scalars['DateTime']['output'];
  timezone?: Maybe<Scalars['Int']['output']>;
};

export type AccountToken = {
  __typename?: 'AccountToken';
  collectionType: CollectionType;
  id: Scalars['Uuid']['output'];
  token: Token;
};

export enum CollectionType {
  Portfolio = 'PORTFOLIO',
  Watchlist = 'WATCHLIST'
}

export type InboxAddressValidation = {
  __typename?: 'InboxAddressValidation';
  isValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  createInbox: Scalars['Boolean']['output'];
  login: Scalars['Boolean']['output'];
  updateAccount: Scalars['Int']['output'];
};


export type MutationRootCreateInboxArgs = {
  inboxAddress: Scalars['String']['input'];
};


export type MutationRootLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRootUpdateAccountArgs = {
  cadence?: InputMaybe<ReportCadence>;
  nextReportAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  class?: Maybe<NewsletterClass>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Uuid']['output'];
  sourceAddress: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum NewsletterClass {
  BulletList = 'BULLET_LIST',
  Confirmation = 'CONFIRMATION',
  CuratedContent = 'CURATED_CONTENT',
  LongForm = 'LONG_FORM',
  MultiTopic = 'MULTI_TOPIC',
  SingleTopic = 'SINGLE_TOPIC',
  Welcome = 'WELCOME'
}

export type QueryRoot = {
  __typename?: 'QueryRoot';
  account: Account;
  accountTokens: Array<AccountToken>;
  newsletters: Array<Newsletter>;
  tokenPriceSnapshot: Array<TokenPriceSnapshotSet>;
  tokens: Array<TokenDb>;
  validateInboxAddress: InboxAddressValidation;
};


export type QueryRootNewslettersArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRootValidateInboxAddressArgs = {
  inboxAddress: Scalars['String']['input'];
};

export enum ReportCadence {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE',
  TwiceWeekly = 'TWICE_WEEKLY',
  Weekly = 'WEEKLY'
}

export type Token = {
  __typename?: 'Token';
  baseTokenId?: Maybe<Scalars['Uuid']['output']>;
  decimals: Scalars['Int']['output'];
  id: Scalars['Uuid']['output'];
  symbol: Scalars['String']['output'];
};

export type TokenDb = {
  __typename?: 'TokenDb';
  baseTokenId?: Maybe<Scalars['Uuid']['output']>;
  createdAt: Scalars['DateTime']['output'];
  decimals: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Uuid']['output'];
  symbol: Scalars['String']['output'];
  tokenName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * TokenPriceSnapshotSet
 *
 * A series of price snapshots for a token.
 *
 * # Fields
 *
 * * `token_symbol` - The symbol of the token
 * * `decimals` - The number of decimals the token is denominated in
 * * `rates` - A list of rates for the token. [0] is the current rate, [1] is the rate 1 day ago, [2] is the rate 7 days ago, and [3] is the rate 30 days ago.
 * * `updated_at` - The time the rates were last updated
 */
export type TokenPriceSnapshotSet = {
  __typename?: 'TokenPriceSnapshotSet';
  decimals: Scalars['Int']['output'];
  percentDeltas: Array<Maybe<Scalars['Float']['output']>>;
  rates: Array<Maybe<Scalars['Float']['output']>>;
  tokenSymbol: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DashboardQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQueryQuery = { __typename?: 'QueryRoot', account: { __typename?: 'Account', id: any, moondipInbox?: string | null } };

export type SetupQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupQueryQuery = { __typename?: 'QueryRoot', account: { __typename?: 'Account', id: any, moondipInbox?: string | null, email: string } };

export type CreateInboxMutationVariables = Exact<{
  inboxAddress: Scalars['String']['input'];
}>;


export type CreateInboxMutation = { __typename?: 'MutationRoot', createInbox: boolean };

export type ValidateInboxAddressQueryVariables = Exact<{
  inboxAddress: Scalars['String']['input'];
}>;


export type ValidateInboxAddressQuery = { __typename?: 'QueryRoot', validateInboxAddress: { __typename?: 'InboxAddressValidation', isValid: boolean, message: string } };

export type DiscoverQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DiscoverQueryQuery = { __typename?: 'QueryRoot', account: { __typename?: 'Account', moondipInbox?: string | null }, newsletters: Array<{ __typename?: 'Newsletter', id: any }> };

export type NewsletterFeedQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type NewsletterFeedQueryQuery = { __typename?: 'QueryRoot', account: { __typename?: 'Account', email: string, moondipInbox?: string | null, confirmed: boolean }, newsletters: Array<{ __typename?: 'Newsletter', id: any, title: string, summary?: string | null, class?: NewsletterClass | null, sourceName: string, createdAt: any }>, tokenPriceSnapshot: Array<{ __typename?: 'TokenPriceSnapshotSet', tokenSymbol: string, rates: Array<number | null>, percentDeltas: Array<number | null>, decimals: number, updatedAt: any }> };

export type UpdateCadenceMutationVariables = Exact<{
  cadence: ReportCadence;
}>;


export type UpdateCadenceMutation = { __typename?: 'MutationRoot', updateAccount: number };

export type UpdateNextReportAtMutationVariables = Exact<{
  nextReportAt: Scalars['DateTime']['input'];
}>;


export type UpdateNextReportAtMutation = { __typename?: 'MutationRoot', updateAccount: number };

export type AccountNewslettersQueryQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
}>;


export type AccountNewslettersQueryQuery = { __typename?: 'QueryRoot', newsletters: Array<{ __typename?: 'Newsletter', id: any, title: string, sourceName: string, createdAt: any }>, account: { __typename?: 'Account', id: any, email: string, moondipInbox?: string | null, confirmed: boolean, cadence: ReportCadence, timezone?: number | null, nextReportAt: any } };

export type AccountInvestmentsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountInvestmentsQueryQuery = { __typename?: 'QueryRoot', accountTokens: Array<{ __typename?: 'AccountToken', token: { __typename?: 'Token', symbol: string } }> };

export type SettingsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQueryQuery = { __typename?: 'QueryRoot', account: { __typename?: 'Account', id: any, cadence: ReportCadence, timezone?: number | null, nextReportAt: any } };


export const DashboardQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"dashboardQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"moondipInbox"}}]}}]}}]} as unknown as DocumentNode<DashboardQueryQuery, DashboardQueryQueryVariables>;
export const SetupQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"setupQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"moondipInbox"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<SetupQueryQuery, SetupQueryQueryVariables>;
export const CreateInboxDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createInbox"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inboxAddress"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createInbox"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inboxAddress"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inboxAddress"}}}]}]}}]} as unknown as DocumentNode<CreateInboxMutation, CreateInboxMutationVariables>;
export const ValidateInboxAddressDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"validateInboxAddress"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inboxAddress"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateInboxAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inboxAddress"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inboxAddress"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isValid"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<ValidateInboxAddressQuery, ValidateInboxAddressQueryVariables>;
export const DiscoverQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"discoverQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moondipInbox"}}]}},{"kind":"Field","name":{"kind":"Name","value":"newsletters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<DiscoverQueryQuery, DiscoverQueryQueryVariables>;
export const NewsletterFeedQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"newsletterFeedQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"moondipInbox"}},{"kind":"Field","name":{"kind":"Name","value":"confirmed"}}]}},{"kind":"Field","name":{"kind":"Name","value":"newsletters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"class"}},{"kind":"Field","name":{"kind":"Name","value":"sourceName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"tokenPriceSnapshot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tokenSymbol"}},{"kind":"Field","name":{"kind":"Name","value":"rates"}},{"kind":"Field","name":{"kind":"Name","value":"percentDeltas"}},{"kind":"Field","name":{"kind":"Name","value":"decimals"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode<NewsletterFeedQueryQuery, NewsletterFeedQueryQueryVariables>;
export const UpdateCadenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCadence"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cadence"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReportCadence"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cadence"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cadence"}}}]}]}}]} as unknown as DocumentNode<UpdateCadenceMutation, UpdateCadenceMutationVariables>;
export const UpdateNextReportAtDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNextReportAt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextReportAt"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"nextReportAt"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextReportAt"}}}]}]}}]} as unknown as DocumentNode<UpdateNextReportAtMutation, UpdateNextReportAtMutationVariables>;
export const AccountNewslettersQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"accountNewslettersQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newsletters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"sourceName"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"moondipInbox"}},{"kind":"Field","name":{"kind":"Name","value":"confirmed"}},{"kind":"Field","name":{"kind":"Name","value":"cadence"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}},{"kind":"Field","name":{"kind":"Name","value":"nextReportAt"}}]}}]}}]} as unknown as DocumentNode<AccountNewslettersQueryQuery, AccountNewslettersQueryQueryVariables>;
export const AccountInvestmentsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"accountInvestmentsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accountTokens"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"symbol"}}]}}]}}]}}]} as unknown as DocumentNode<AccountInvestmentsQueryQuery, AccountInvestmentsQueryQueryVariables>;
export const SettingsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"settingsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"cadence"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}},{"kind":"Field","name":{"kind":"Name","value":"nextReportAt"}}]}}]}}]} as unknown as DocumentNode<SettingsQueryQuery, SettingsQueryQueryVariables>;