const DateBlock = ({ date }: { date: Date }) => (
  <div className="sticky top-[56px] z-10 border border-solid border-black bg-black bg-noise-pattern px-4 py-2 font-semibold text-white">
    <h3>
      {date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      })}
    </h3>
  </div>
);

export default DateBlock;
