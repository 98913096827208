import clsx from "clsx";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <section
      className={clsx("w-full px-4 sm:px-6 lg:px-8", className)}
      {...props}
    >
      {children}
    </section>
  );
};

export default Container;
