import { useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
}

export default function Modal({ onClose, children }: ModalProps) {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-50 flex w-screen items-center justify-center bg-black bg-opacity-50">
      <div className="w-11/12 max-w-prose border border-solid border-slate-900 bg-white">
        <div className="flex flex-row-reverse px-2 pb-4 pt-2">
          <XMarkIcon
            className="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-300 active:text-gray-600"
            onClick={onClose}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

interface ModalHeaderProps {
  className?: string;
  children: React.ReactNode;
}

export const ModalHeader = ({ children, className }: ModalHeaderProps) => {
  return (
    <div
      className={clsx("mb-8 flex items-center text-2xl font-bold", className)}
    >
      {children}
    </div>
  );
};
