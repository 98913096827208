import { createContext, useState, useContext } from "react";

type GlobalState = {
  enableDiscoveryGuide: boolean;
};

const AppContext = createContext<
  Partial<{
    globalState: GlobalState;
    setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
  }>
>({});

type AppContextProps = {
  children: React.ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProps) => {
  const [globalState, setGlobalState] = useState<GlobalState>({
    enableDiscoveryGuide: false,
  });

  return (
    <AppContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
