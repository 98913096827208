import { STATIC_APP_BASE_URL } from "../config";

interface Props {
  sourceName: string;
  title: string;
  createdAt: string;
  id: string;
}

const NewsletterCard = ({ id, sourceName, title, createdAt }: Props) => {
  const url = `${STATIC_APP_BASE_URL}/newsletter/${id}`;

  const currentDate = new Date();
  const newsletterDate = new Date(createdAt);

  let formattedDate;
  if (
    currentDate.getDate() === newsletterDate.getDate() &&
    currentDate.getMonth() === newsletterDate.getMonth() &&
    currentDate.getFullYear() === newsletterDate.getFullYear()
  ) {
    formattedDate = newsletterDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    formattedDate = newsletterDate.toLocaleDateString([], {
      month: "short",
      day: "2-digit",
    });
  }

  return (
    <a
      className="block border-b border-black p-4 hover:bg-gray-100"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex justify-between text-sm uppercase text-gray-500">
        <span className="w-2/3">{sourceName}</span>
        <span>{formattedDate}</span>
      </div>
      <p className="mt-2 w-3/4 font-semibold">{title}</p>
    </a>
  );
};

export default NewsletterCard;
