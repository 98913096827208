import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

interface FeedCardProps extends Omit<LinkProps, "to"> {
  children: React.ReactNode;
  to?: string;
}

const FeedCard = ({ children, to, ...props }: FeedCardProps) => {
  const handleClick = () => {
    if (to && !window.getSelection()?.toString()) {
      window.open(to, "_blank");
    }
  };

  return (
    <article
      className={clsx(
        "group w-full border-b border-solid border-black px-5 py-4 sm:border-l sm:border-r",
        {
          "cursor-pointer sm:hover:bg-violet-200": !!to,
        }
      )}
      onMouseUp={handleClick}
    >
      {children}
    </article>
  );
};

export default FeedCard;
