import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { graphql } from "@/gql";
import { graphQLClient } from "@/utils/request";
import { useQuery } from "@tanstack/react-query";
import NewsletterItem from "./NewsletterItem";
import TokenSnapshotCard from "./TokenSnapshotCard";
import LoadingIcon from "@/components/icons/LoadingIcon";
import clsx from "clsx";
import Button from "@/components/Button";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import ConfirmEmailNotice from "@/components/ConfirmEmailNotice";
import NewsletterList from "./Feed";
import DateBlock from "./DateBlock";

const newsletterFeedQuery = graphql(`
  query newsletterFeedQuery($limit: Int) {
    account {
      email
      moondipInbox
      confirmed
    }
    newsletters(limit: $limit) {
      id
      title
      summary
      class
      sourceName
      createdAt
    }
    tokenPriceSnapshot {
      tokenSymbol
      rates
      percentDeltas
      decimals
      updatedAt
    }
  }
`);

const LIMIT_INCREMENT = 20;

export default function Feed() {
  // set initial query limit
  const [newsletterLimit, setNewsletterLimit] = useState(LIMIT_INCREMENT);

  // TODO handle error
  // TODO fetch tokenPriceSnapshot separately
  const { data, isFetching, refetch } = useQuery(
    ["feed", newsletterLimit],
    async () => {
      return graphQLClient.request(newsletterFeedQuery, {
        limit: newsletterLimit,
      });
    },
    {
      refetchOnWindowFocus: "always",
      keepPreviousData: true,
    }
  );

  // define handleScroll and window focus event listener
  /* Manage Newsletter Query Limit Based on Scroll Position */
  useEffect(() => {
    const handleScroll = debounce(
      () => {
        const { scrollHeight, scrollTop, clientHeight } =
          document.documentElement;
        const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

        if (scrollPercentage > 0.7) {
          if (data?.newsletters.length === newsletterLimit) {
            setNewsletterLimit((prev) => prev + LIMIT_INCREMENT);
          }
        }
      },
      10,
      { immediate: true }
    );

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data?.newsletters, newsletterLimit]);

  /* Manage Refetching Based On Window Focus */
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;

    const onFocus = () => {
      intervalId = setInterval(() => {
        refetch();
      }, 5000);
    };

    const onBlur = () => {
      clearInterval(intervalId);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      clearInterval(intervalId);
    };
  }, [refetch]);

  // TODO define UI
  if (!data) {
    return <div>Loading...</div>;
  }

  const viewComponents: [string, (() => JSX.Element) | null][] = [
    [
      "confirm-email-notice",
      !!data.account?.email &&
      !!data.account?.moondipInbox &&
      data.newsletters.length > 0 &&
      !data.account?.confirmed
        ? () => (
            <div className="px-2 sm:px-0">
              <ConfirmEmailNotice email={data.account?.email} />
            </div>
          )
        : null,
    ],
    [
      "header",
      () => (
        <div className="sticky top-0 z-20 bg-neutral-100 p-2">
          <h1 className="text-3xl text-slate-900">Home</h1>
        </div>
      ),
    ],
    [
      "zero-state-with-inbox",
      data.newsletters.length === 0 && data.account.moondipInbox
        ? () => (
            <div className="mt-16 flex flex-col items-center gap-y-12">
              <h2 className="border-l-4 border-solid border-gray-500 pl-2">
                When your newsletters get summarized, they&apos;ll show up here.
                Go subscribe to some.
              </h2>
              <Button variant="outline" className="w-full" href="/discover">
                Discover Newsletters
                <ArrowRightIcon className="ml-2 inline h-5 w-5" />
              </Button>
            </div>
          )
        : null,
    ],
    [
      "zero-state-no-inbox",
      !data.account.moondipInbox
        ? () => (
            <div className="mt-16 flex flex-col items-center gap-y-12">
              <h2 className="border-l-4 border-solid border-gray-500 pl-2">
                When your newsletters get summarized, they&apos;ll show up here.
                But first, set up your MoonDip inbox.
              </h2>
              <Button variant="outline" className="w-full" href="/inbox">
                Set Up Inbox
                <ArrowRightIcon className="ml-2 inline h-5 w-5" />
              </Button>
            </div>
          )
        : null,
    ],
    ["date-block", () => <DateBlock date={new Date()} />],
    [
      "token-price-snapshot",
      data.tokenPriceSnapshot.length > 0
        ? () => <TokenSnapshotCard tokenSnapshots={data.tokenPriceSnapshot} />
        : null,
    ],
    [
      "feed-newsletters",
      data.newsletters.length > 0
        ? () => (
            <>
              {/* {data.newsletters.map((newsletter) => (
                <NewsletterItem key={newsletter.id} newsletter={newsletter} />
              ))} */}
              <NewsletterList newsletters={data.newsletters} />
              <LoadingIcon
                className={clsx("ml-2 mt-1 h-3 w-3", {
                  invisible:
                    !isFetching || data.newsletters.length < LIMIT_INCREMENT,
                })}
              />
            </>
          )
        : null,
    ],
  ];

  return (
    <section className="flex flex-col items-center">
      <div className="w-full">
        {viewComponents.map(([id, renderComponent]) =>
          renderComponent ? (
            <React.Fragment key={id}>{renderComponent()}</React.Fragment>
          ) : null
        )}
      </div>
    </section>
  );
}
