import { useFlashMessage } from "@/components/FlashMessage";
import { API_BASE_URL } from "@/config";
import Button from "@/components/Button";

const ConfirmEmailNotice = ({ email }: { email: string }) => {
  const { setFlashMessage } = useFlashMessage();

  const handleResendConfirmationEmail = async () => {
    try {
      const res = await fetch(`${API_BASE_URL}/register/resend`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      setFlashMessage("Confirmation email resent.", "success");
    } catch (err) {
      setFlashMessage("Error sending confirmation email.", "error");
    }
  };

  return (
    <div className="mt-4 max-w-prose  bg-slate-900 p-4 text-white shadow">
      <h2 className="mb-2 font-semibold capitalize">Confirm Your Account</h2>
      <p className="mb-4">
        Check <span className="underline">{email}</span> for a confirmation
        email.
      </p>
      <p>
        <Button
          size="small"
          color="white"
          onClick={handleResendConfirmationEmail}
        >
          Resend Confirmation Email
        </Button>
      </p>
    </div>
  );
};

export default ConfirmEmailNotice;
