import { useEffect, useState, useRef, useCallback } from "react";
import { graphql } from "@/gql";
import { useMutation } from "@tanstack/react-query";
import { graphQLClient } from "@/utils/request";
import { useForm } from "react-hook-form";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import debounce from "debounce";
import Container from "@/components/Container";
import Button from "@/components/Button";
import { MOONDIP_INBOX_DOMAIN } from "@/config";
import LoadingIcon from "@/components/icons/LoadingIcon";
import { useFlashMessage } from "@/components/FlashMessage";
import { trackEvent } from "@/utils/tracking";

export interface CreateInboxFormProps {
  email: string;
  onSuccess: () => void;
}

const CreateInboxForm = ({ email, onSuccess }: CreateInboxFormProps) => {
  const { setFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailPrefix] = email?.split("@") || [];
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      moondipEmailPrefix: emailPrefix,
    },
  });

  const createInboxMutation = graphql(`
    mutation createInbox($inboxAddress: String!) {
      createInbox(inboxAddress: $inboxAddress)
    }
  `);

  const mutation = useMutation(
    (inboxAddress: string) =>
      graphQLClient.request(createInboxMutation, { inboxAddress }),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error: any) => {
        setIsLoading(false);
        setFlashMessage(error.response.errors[0].message, "error");
      },
    }
  );

  const handleSetIsValid = (isValid: boolean) => {
    setIsValid(isValid);
  };

  const onSubmit = (data: any) => {
    trackEvent("create_inbox");

    setIsLoading(true);
    mutation.mutate(data.moondipEmailPrefix + MOONDIP_INBOX_DOMAIN);
  };

  return (
    <Container className="py-10">
      <div className="flex w-full flex-col items-center">
        <div className="sm:w-3/5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label id="email" className="mb-2 font-semibold">
              Create your email inbox for newsletters
            </label>
            <p className="sm:text-l mb-8 mt-1 text-base text-gray-500">
              You&apos;ll use this address to subscribe to newsletters.
              Confirmation emails will arrive in this inbox, and your digests
              will be sent to <span className="underline">{email}</span>.
            </p>
            <CreateInboxInput
              register={register("moondipEmailPrefix")}
              watch={watch}
              setIsValid={handleSetIsValid}
            />
            <Button
              className="mt-8 w-full"
              type="submit"
              loading={isLoading}
              disabled={!isValid}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

const validateInboxAddressQuery = graphql(`
  query validateInboxAddress($inboxAddress: String!) {
    validateInboxAddress(inboxAddress: $inboxAddress) {
      isValid
      message
    }
  }
`);

interface CreateInboxInputProps {
  register: any;
  watch: any;
  setIsValid: any;
}

type ValidationState = {
  isValid: boolean | null;
  message: string | null;
  isLoading: boolean;
};

const CreateInboxInput = ({
  register,
  watch,
  setIsValid,
}: CreateInboxInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFlashMessage } = useFlashMessage();
  const [{ isValid, message, isLoading }, setValidation] =
    useState<ValidationState>({
      isValid: null,
      message: null,
      isLoading: true,
    });
  const _setIsValid = useCallback(setIsValid, [setIsValid]);

  const debouncedValidateInboxAddress = debounce(async (inboxAddr: string) => {
    try {
      const data = await graphQLClient.request(validateInboxAddressQuery, {
        inboxAddress: inboxAddr + MOONDIP_INBOX_DOMAIN,
      });

      /* Update State */
      setValidation({
        isValid: data?.validateInboxAddress?.isValid || null,
        message: data?.validateInboxAddress?.message || null,
        isLoading: false,
      });

      /* Set Validity For Submit Button */
      if (data?.validateInboxAddress?.isValid) {
        _setIsValid(true);
      }
    } catch (error) {
      setFlashMessage("Yikes. Something is broken.", "error");
      return false;
    }
  }, 500);

  /* Validate Inbox Address */
  // Refac debounce to resolve warning.
  const validateInboxAddress = useCallback(debouncedValidateInboxAddress, [
    debouncedValidateInboxAddress,
  ]);

  const moondipEmailPrefix = watch("moondipEmailPrefix");

  useEffect(() => {
    setValidation((state) => ({ ...state, isLoading: true }));
    _setIsValid(false);
    validateInboxAddress(moondipEmailPrefix);
  }, [moondipEmailPrefix]);

  /* Focus Input on Click */
  const handleInputClick = () => {
    const input = inputRef.current;
    if (input) {
      input.focus();
      const len = input.value.length;
      input.selectionStart = len;
      input.selectionEnd = len;
    }
  };

  const { ref, ...inputRegister } = register;

  return (
    <div className="grid">
      <div
        className="flex cursor-pointer appearance-none justify-end overflow-hidden rounded-md border border-gray-200 bg-white px-3 py-2 text-gray-900 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
        onClick={handleInputClick}
      >
        <input
          id="moondipEmailPrefix"
          className="mr-1 bg-transparent text-right text-base text-blue-600 focus:outline-none"
          onClick={(e) => e.stopPropagation()}
          {...inputRegister}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          size={moondipEmailPrefix.length}
          required
        />
        <span className="whitespace-nowrap text-base">
          {MOONDIP_INBOX_DOMAIN}
        </span>
      </div>
      <div className="mt-2 flex items-center">
        {isLoading ? (
          <div className="flex items-center rounded-md bg-white p-2.5">
            <LoadingIcon className="text-white" />
          </div>
        ) : (
          <div
            className={clsx("flex gap-x-1 rounded-md p-2", {
              "bg-green-50": isValid && !isLoading,
              "bg-red-50": !isValid && !isLoading,
            })}
          >
            {isValid ? (
              <CheckCircleIcon className="h-6 w-6 text-green-500" />
            ) : (
              <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
            )}
            <p
              className={clsx("text-sm", {
                "text-green-500": isValid,
                "text-red-500": !isValid,
              })}
            >
              {message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateInboxForm;
