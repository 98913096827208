interface Props {
  className?: string;
}

const LogoIcon = (props: Props) => {
  return (
    <svg
      viewBox="0 0 200 200"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="100" cy="100" r="96" stroke="currentColor" strokeWidth="8" />
      <circle cx="100" cy="100" r="10" fill="currentColor" />
    </svg>
  );
};

export default LogoIcon;
