import {
  Fragment,
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import { Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

// TODO implement enum for type when converting to TS

interface FlashMessageContextType {
  setFlashMessage: (
    message: string,
    type: "success" | "notification" | "error",
    timeout?: number
  ) => void;
}

interface Props {
  children: React.ReactNode;
}

type FlashMessageType = "success" | "notification" | "error";

interface Flash {
  message: string;
  type: FlashMessageType;
  timeout: number;
}

const FlashMessageContext = createContext<FlashMessageContextType | null>(null);

export const FlashMessageProvider = ({ children }: Props) => {
  const [flash, setFlash] = useState<Flash | null>(null);

  useEffect(() => {
    if (flash) {
      const timer = setTimeout(() => {
        setFlash(null);
      }, flash.timeout); // Set a timer for how long the flash should be displayed
      return () => clearTimeout(timer);
    }
  }, [flash]);

  const setFlashMessage = (
    message: string,
    type: FlashMessageType,
    timeout = 5000
  ) => {
    setFlash({ message, type, timeout });
  };

  return (
    <FlashMessageContext.Provider value={{ setFlashMessage }}>
      {children}
      {flash && <FlashMessage {...flash} />}
    </FlashMessageContext.Provider>
  );
};

export const useFlashMessage = () => {
  const context = useContext(FlashMessageContext);

  if (!context) {
    throw new Error(
      "useFlashMessage must be used within a FlashMessageProvider."
    );
  }

  return context;
};

const _useFlashMessage = () => {
  const [flashMessage, setFlashMessage] = useState<Flash | null>(null);

  const showFlashMessage = (
    message: string,
    type: FlashMessageType,
    timeout = 5000
  ) => {
    setFlashMessage({ message, type, timeout });

    /* Stop rendering node 200 ms after hiding it */
    setTimeout(() => {
      setFlashMessage(null);
    }, timeout + 200);
  };

  return [flashMessage, showFlashMessage];
};

export const FlashMessage = ({ message, type, timeout }: Flash) => {
  const [show, setShow] = useState(true);

  setTimeout(() => {
    setShow(false);
  }, timeout);

  switch (type) {
    case "error":
      return (
        <>
          {/* Global notification live region, render this permanently at the end of the document */}
          <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-6 sm:p-6"
          >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5 text-sm font-medium text-red-600">
                        <p>
                          <b>{message}</b>
                        </p>
                        <p className="mt-4">
                          If the issue persists send an email to{" "}
                          <a
                            className="underline"
                            href="mailto:founders@moondip.xyz"
                          >
                            founders@moondip.xyz
                          </a>
                          .
                        </p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </>
      );

    case "success":
      return (
        <>
          {/* Global notification live region, render this permanently at the end of the document */}
          <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-6 sm:p-6"
          >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-green-600">
                          {message}
                        </p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </>
      );

    default:
      return;
  }
};

export default _useFlashMessage;
