import { useState } from "react";
import { graphQLClient } from "@/utils/request";
import { useLoaderData } from "react-router-dom";
import { Account } from "@/gql/graphql";
import { graphql } from "@/gql";
import Button from "@/components/Button";
import Container from "@/components/Container";
import Copyable from "@/components/Copyable";
import SubstackIcon from "@/components/icons/SubstackIcon";
import Modal, { ModalHeader } from "@/components/Modal";
import {
  ArrowTopRightOnSquareIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Card, { CardVariant } from "@/components/Card";
import recommendedNewsletters from "./newsletters.json";
import clsx from "clsx";
import { trackEvent } from "@/utils/tracking";
import PageHeader from "@/components/PageHeader";

const discoverQueryDocument = graphql(`
  query discoverQuery($limit: Int) {
    account {
      moondipInbox
    }
    newsletters(limit: $limit) {
      id
    }
  }
`);

interface LoaderData {
  account: Partial<Account> & Pick<Account, "moondipInbox">;
  hasReceiedNewsletter: boolean;
  error: Error | null;
}

export const discoverLoader = async () => {
  try {
    const data = await graphQLClient.request(discoverQueryDocument, {
      limit: 1,
    });
    return {
      account: data.account,
      hasReceiedNewsletter: !!data.newsletters.length,
      error: null,
    };
  } catch (error) {
    return { account: undefined, error };
  }
};

const Discover = () => {
  const { account, hasReceiedNewsletter, error } =
    useLoaderData() as LoaderData;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubstackModalOpen = () => {
    trackEvent("substack_modal_open");
    setIsModalOpen(true);
  };

  const handleSubstackModalClose = () => {
    setIsModalOpen(false);
  };

  if (error) {
    // render simple error message that displays in the middle
    // of the page
    return (
      <div className="flex items-center justify-center pt-20">
        <h1>Something went wrong. Our apologies.</h1>
      </div>
    );
  }

  return (
    <div className="px-2">
      {isModalOpen && (
        <SubstackIntegrationModal
          onClose={handleSubstackModalClose}
          moondipInbox={account.moondipInbox!}
        />
      )}
      <PageHeader>Discover</PageHeader>
      <div className="border-b border-dashed border-black py-4">
        <IntegrationOptions
          moondipInbox={account.moondipInbox!}
          hasReceiedNewsletter={hasReceiedNewsletter}
          onSubstackModalOpen={handleSubstackModalOpen}
        />
      </div>
      <NewsletterRecommendations moondipInbox={account.moondipInbox!} />
    </div>
  );
};

export default Discover;

interface SubstackIntegrationModalProps {
  onClose: () => void;
  moondipInbox: string;
}

const SubstackIntegrationModal = ({
  onClose,
  moondipInbox,
}: SubstackIntegrationModalProps) => {
  return (
    <Modal onClose={onClose}>
      <Container className="pb-8">
        <ModalHeader className="justify-center">
          <SubstackIcon className="mr-2 h-4 w-4 text-[#ff6719]" />
          Integrate with Substack
        </ModalHeader>
        <div className="flex flex-col gap-y-8 text-gray-600">
          <div className="flex flex-col gap-y-4">
            <p>
              Receive all of your Substack newsletters in your MoonDip inbox by
              setting your Substack account&apos;s email address to your MoonDip
              address.
            </p>
            <p>
              Many of the best newsletters are on Substack. So, if you
              don&apos;t already have an account, we recommend creating one.
            </p>
          </div>
          <div>
            <Copyable
              value={moondipInbox}
              className="border-b border-solid border-slate-900 pb-4 font-semibold"
            />
          </div>
          <div className="flex flex-col gap-y-6">
            <div>
              <h3 className="mb-1 font-semibold">
                Don&apos;t have a Substack account?
              </h3>
              <p className="mb-2 text-gray-600">
                <Link
                  to="https://substack.com"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center gap-x-1 p-0 font-semibold text-[#ff6719] underline hover:text-orange-400 active:text-orange-600"
                >
                  Create a Substack account
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                </Link>{" "}
                with your MoonDip address.
              </p>
            </div>
            <div className="flex flex-col gap-y-2">
              <Button
                className="flex w-full items-center justify-center gap-x-2 bg-[#ff6719] font-bold text-white hover:bg-orange-400 active:bg-orange-600"
                color="custom"
                href="https://substack.com/settings"
                target="_blank"
              >
                Update Your Substack Email
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </Button>
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

interface IntegrationOptionsProps {
  moondipInbox: string;
  hasReceiedNewsletter: boolean;
  onSubstackModalOpen: () => void;
}

const IntegrationOptions = ({
  moondipInbox,
  hasReceiedNewsletter,
  onSubstackModalOpen,
}: IntegrationOptionsProps) => {
  return (
    <>
      <div className={clsx(hasReceiedNewsletter ? "hidden" : "")}>
        <h2 className="font-semibold">Quick Start</h2>
        <p className="mb-8 mt-3">
          Get all of your Substack newsletters sent to your MoonDip.
        </p>
      </div>
      <Button
        className="w-full"
        variant="outline"
        onClick={onSubstackModalOpen}
      >
        <SubstackIcon className="mr-2 h-4 w-4 text-[#ff6719]" /> Integrate with
        Substack
      </Button>
    </>
  );
};

interface NewsletterRecommendationsProps {
  moondipInbox?: string;
}

const NewsletterRecommendations = ({
  moondipInbox,
}: NewsletterRecommendationsProps) => {
  return (
    <div className="pb-4 pt-8">
      <h2 className="mb-2 font-semibold text-slate-900">
        Newsletter Recommendations
      </h2>
      <div className="sticky top-0 mt-2 bg-neutral-100">
        {moondipInbox ? (
          <Card variant={CardVariant.dashed}>
            <p className="mb-4">
              Subscribe to newsletters with your MoonDip address.
            </p>
            <div>
              <Copyable
                value={moondipInbox}
                className="border-b border-solid border-slate-900 pb-4"
              />
            </div>
          </Card>
        ) : (
          <Card className="bg-slate-900 text-white" variant={CardVariant.solid}>
            <p className="mb-4">
              Before subscribing to newsletters with MoonDip, you need to set up
              your inbox.
            </p>
            <Button
              className="bg-white hover:text-blue-400 active:text-blue-600"
              size="small"
              variant="outline"
              href="/inbox"
            >
              Set Up Inbox
              <ArrowRightIcon className="ml-1 inline h-4 w-4" />
            </Button>
          </Card>
        )}
      </div>
      <Card className="my-4 overflow-y-scroll" flat>
        {recommendedNewsletters.sections.map((section) => {
          return (
            <table className="w-full" key={section.name}>
              <thead>
                <tr>
                  <td colSpan={2}>
                    <h2 className="mt-8 pb-2 text-base font-semibold">
                      {section.name}
                    </h2>
                  </td>
                </tr>
              </thead>
              <tbody className="border-l-solid border-l-4 border-r border-t border-l-gray-500 border-r-black border-t-black text-sm">
                {section.newsletters.map((newsletter) => {
                  return (
                    <tr className="border-b border-black" key={newsletter.href}>
                      <td className="sm:40 w-32 border-r border-solid border-black py-2 pl-2 pr-1">
                        <div className="flex flex-col gap-y-2">
                          <a
                            className="font-semibold underline"
                            href={newsletter.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {newsletter.name}
                          </a>
                        </div>
                      </td>
                      <td className="py-2 pl-2 align-top">
                        <p>{newsletter.description}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </Card>
    </div>
  );
};
