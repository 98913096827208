import { TokenPriceSnapshotSet } from "@/gql/graphql";
import FeedCard from "./FeedCard";
import clsx from "clsx";

interface TokenSnapshotCardProps {
  tokenSnapshots: TokenPriceSnapshotSet[];
}

const TokenSnapshotCard = ({ tokenSnapshots }: TokenSnapshotCardProps) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  return (
    <FeedCard>
      <h2 className="mb-3 text-xs font-semibold uppercase">Token Prices</h2>
      <div className="no-scrollbar overflow-x-auto border-l-4 border-solid border-l-gray-500 shadow">
        <table className="w-full table-auto border-t border-gray-200 bg-white text-sm">
          <thead>
            <tr>
              <th
                className={clsx(
                  "sticky left-0 border-b border-solid border-b-gray-500 bg-white px-2 text-left"
                )}
              >
                Token
              </th>
              <th className="border-b-solid border-b border-b-gray-500 px-2 text-right">
                Price
              </th>
              <th className="border-b-solid border-b border-b-gray-500 px-2 text-right">
                1dΔ%
              </th>
              <th className="border-b-solid border-b border-b-gray-500 px-2 text-right">
                7dΔ%
              </th>
              <th className="border-b-solid border-b border-r border-b-gray-500 px-2 text-right">
                30dΔ%
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {tokenSnapshots.map((snapshot) => {
              return (
                <tr
                  className="border-b border-gray-200"
                  key={snapshot.tokenSymbol}
                >
                  <td className="border-gray-20 sticky left-0 border-r bg-white py-2 pl-2 pr-1">
                    {snapshot.tokenSymbol}
                  </td>
                  <td className="border-gray-20 border-r pl-2 pr-2 text-right">
                    {snapshot.rates[0]
                      ? currencyFormatter.format(snapshot.rates[0])
                      : "-"}
                  </td>
                  <DeltaRow delta={snapshot.percentDeltas[0]} />
                  <DeltaRow delta={snapshot.percentDeltas[1]} />
                  <DeltaRow delta={snapshot.percentDeltas[2]} />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </FeedCard>
  );
};

const DeltaRow = ({ delta }: { delta?: number | null }) => {
  let indicator = "";
  let color = "text-slate-600";

  const className = "border-gray-20 border-r pl-2 pr-2";

  if (!delta) {
    return <td className={clsx("text-center", className)}>-</td>;
  }

  if (delta > 0) {
    indicator = "+";
    color = "text-green-600";
  } else if (delta < 0) {
    color = "text-red-600";
  }
  return (
    <td className={clsx("text-right", className, color)}>
      {indicator}
      {delta.toFixed(2)}
    </td>
  );
};

export default TokenSnapshotCard;
