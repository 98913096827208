import { useState } from "react";
import { graphQLClient } from "@/utils/request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "@/gql";
import { Label } from "@/components/Fields";
import { ReportCadence } from "@/gql/graphql";
import { useFlashMessage } from "@/components/FlashMessage";
import Dropdown, { DropdownVariant } from "@/components/Dropdown";
import LoadingIcon from "@/components/icons/LoadingIcon";
import Card, { CardVariant } from "@/components/Card";

export interface SettingsProps {
  cadence: ReportCadence;
  nextReportAt: string;
}

const Settings = ({ cadence, nextReportAt }: SettingsProps) => {
  return (
    <Card className="inline-block" variant={CardVariant.dashed}>
      <div className="inline-flex flex-col gap-x-4 gap-y-4 sm:flex-row">
        <CadenceForm initialCadence={cadence} />
        <NextReportAtForm nextReportAt={nextReportAt} />
      </div>
    </Card>
  );
};

export default Settings;

const cadenceOptions = [
  {
    label: "Daily",
    value: ReportCadence.Daily,
  },
  {
    label: "Twice Weekly (Sun, Wed)",
    value: ReportCadence.TwiceWeekly,
  },
  {
    label: "Weekly (Sun)",
    value: ReportCadence.Weekly,
  },
  {
    label: "Pause",
    value: ReportCadence.None,
  },
];

interface CadenceFormProps {
  initialCadence: ReportCadence;
}

const CadenceForm = ({ initialCadence }: CadenceFormProps) => {
  const [cadence, setCadence] = useState(initialCadence);
  const [loading, setLoading] = useState(false);
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();

  const updateCadenceMutation = graphql(`
    mutation UpdateCadence($cadence: ReportCadence!) {
      updateAccount(cadence: $cadence)
    }
  `);

  const mutation = useMutation(
    (selectedCadence: ReportCadence) =>
      graphQLClient.request(updateCadenceMutation, {
        cadence: selectedCadence,
      }),
    {
      onSuccess: async () => {
        try {
          queryClient.refetchQueries(["account"]);
        } catch (err: any) {
          setFlashMessage(err.message, "error");
        }
        setLoading(false);
      },
      onError: (err: Error) => {
        setLoading(false);
        setFlashMessage(err.message, "error");
      },
    }
  );

  const handleSelect = (selectedCadence: ReportCadence) => {
    setCadence(selectedCadence);
    setLoading(true);
    mutation.mutate(selectedCadence);
  };

  return (
    <div className="flex items-center gap-x-2">
      <Label id="label">Cadence:</Label>
      <div className="flex items-center gap-x-2">
        <Dropdown
          variant={DropdownVariant.text}
          options={cadenceOptions}
          value={cadence}
          onSelect={handleSelect}
          position="left"
        />
        {loading && <LoadingIcon className="h-3.5 w-3.5 text-blue-600" />}
      </div>
    </div>
  );
};

const deliveryHours = [
  { label: "6:00 AM", value: 6 },
  { label: "7:00 AM", value: 7 },
  { label: "8:00 AM", value: 8 },
  { label: "9:00 AM", value: 9 },
  { label: "10:00 AM", value: 10 },
  { label: "11:00 AM", value: 11 },
  { label: "12:00 PM", value: 12 },
  { label: "1:00 PM", value: 13 },
  { label: "2:00 PM", value: 14 },
  { label: "3:00 PM", value: 15 },
  { label: "4:00 PM", value: 16 },
  { label: "5:00 PM", value: 17 },
  { label: "6:00 PM", value: 18 },
  { label: "7:00 PM", value: 19 },
  { label: "8:00 PM", value: 20 },
  { label: "9:00 PM", value: 21 },
  { label: "10:00 PM", value: 22 },
  { label: "11:00 PM", value: 23 },
  { label: "12:00 AM", value: 0 },
  { label: "1:00 AM", value: 1 },
  { label: "2:00 AM", value: 2 },
  { label: "3:00 AM", value: 3 },
  { label: "4:00 AM", value: 4 },
  { label: "5:00 AM", value: 5 },
];

interface NextReportAtProps {
  nextReportAt: string;
}

const NextReportAtForm = ({ nextReportAt }: NextReportAtProps) => {
  const [hour, setHour] = useState(new Date(nextReportAt).getHours());
  const [loading, setLoading] = useState(false);
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();

  const updateNextReportAtMutation = graphql(`
    mutation UpdateNextReportAt($nextReportAt: DateTime!) {
      updateAccount(nextReportAt: $nextReportAt)
    }
  `);

  const mutation = useMutation(
    (nextReportAt: string) =>
      graphQLClient.request(updateNextReportAtMutation, { nextReportAt }),
    {
      onSuccess: async () => {
        try {
          queryClient.refetchQueries(["account"]);
        } catch (err: any) {
          setFlashMessage(err.message, "error");
        }
        setLoading(false);
      },
      onError: (err: Error) => {
        setLoading(false);
        setFlashMessage(err.message, "error");
      },
    }
  );

  const handleSelect = (selectedHour: number) => {
    setHour(selectedHour);
    setLoading(true);

    const date = new Date(nextReportAt);
    date.setHours(selectedHour);
    mutation.mutate(date.toISOString());
  };

  return (
    <div className="flex items-center gap-x-2">
      <Label id="label">Delivery Time:</Label>
      <div className="flex items-center gap-x-2">
        <Dropdown
          variant={DropdownVariant.text}
          options={deliveryHours}
          value={hour}
          onSelect={handleSelect}
          position="left"
        />
        {loading && <LoadingIcon className="h-4 w-4 text-blue-600" />}
      </div>
    </div>
  );
};
