/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query dashboardQuery {\n    account {\n      id\n      moondipInbox\n    }\n  }\n": types.DashboardQueryDocument,
    "\n  query setupQuery {\n    account {\n      id\n      moondipInbox\n      email\n    }\n  }\n": types.SetupQueryDocument,
    "\n    mutation createInbox($inboxAddress: String!) {\n      createInbox(inboxAddress: $inboxAddress)\n    }\n  ": types.CreateInboxDocument,
    "\n  query validateInboxAddress($inboxAddress: String!) {\n    validateInboxAddress(inboxAddress: $inboxAddress) {\n      isValid\n      message\n    }\n  }\n": types.ValidateInboxAddressDocument,
    "\n  query discoverQuery($limit: Int) {\n    account {\n      moondipInbox\n    }\n    newsletters(limit: $limit) {\n      id\n    }\n  }\n": types.DiscoverQueryDocument,
    "\n  query newsletterFeedQuery($limit: Int) {\n    account {\n      email\n      moondipInbox\n      confirmed\n    }\n    newsletters(limit: $limit) {\n      id\n      title\n      summary\n      class\n      sourceName\n      createdAt\n    }\n    tokenPriceSnapshot {\n      tokenSymbol\n      rates\n      percentDeltas\n      decimals\n      updatedAt\n    }\n  }\n": types.NewsletterFeedQueryDocument,
    "\n    mutation UpdateCadence($cadence: ReportCadence!) {\n      updateAccount(cadence: $cadence)\n    }\n  ": types.UpdateCadenceDocument,
    "\n    mutation UpdateNextReportAt($nextReportAt: DateTime!) {\n      updateAccount(nextReportAt: $nextReportAt)\n    }\n  ": types.UpdateNextReportAtDocument,
    "\n  query accountNewslettersQuery($limit: Int!) {\n    newsletters(limit: $limit) {\n      id\n      title\n      sourceName\n      createdAt\n    }\n    account {\n      id\n      email\n      moondipInbox\n      confirmed\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n": types.AccountNewslettersQueryDocument,
    "\n  query accountInvestmentsQuery {\n    accountTokens {\n      token {\n        symbol\n      }\n    }\n  }\n": types.AccountInvestmentsQueryDocument,
    "\n  query settingsQuery {\n    account {\n      id\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n": types.SettingsQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query dashboardQuery {\n    account {\n      id\n      moondipInbox\n    }\n  }\n"): (typeof documents)["\n  query dashboardQuery {\n    account {\n      id\n      moondipInbox\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query setupQuery {\n    account {\n      id\n      moondipInbox\n      email\n    }\n  }\n"): (typeof documents)["\n  query setupQuery {\n    account {\n      id\n      moondipInbox\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation createInbox($inboxAddress: String!) {\n      createInbox(inboxAddress: $inboxAddress)\n    }\n  "): (typeof documents)["\n    mutation createInbox($inboxAddress: String!) {\n      createInbox(inboxAddress: $inboxAddress)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query validateInboxAddress($inboxAddress: String!) {\n    validateInboxAddress(inboxAddress: $inboxAddress) {\n      isValid\n      message\n    }\n  }\n"): (typeof documents)["\n  query validateInboxAddress($inboxAddress: String!) {\n    validateInboxAddress(inboxAddress: $inboxAddress) {\n      isValid\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query discoverQuery($limit: Int) {\n    account {\n      moondipInbox\n    }\n    newsletters(limit: $limit) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query discoverQuery($limit: Int) {\n    account {\n      moondipInbox\n    }\n    newsletters(limit: $limit) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query newsletterFeedQuery($limit: Int) {\n    account {\n      email\n      moondipInbox\n      confirmed\n    }\n    newsletters(limit: $limit) {\n      id\n      title\n      summary\n      class\n      sourceName\n      createdAt\n    }\n    tokenPriceSnapshot {\n      tokenSymbol\n      rates\n      percentDeltas\n      decimals\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query newsletterFeedQuery($limit: Int) {\n    account {\n      email\n      moondipInbox\n      confirmed\n    }\n    newsletters(limit: $limit) {\n      id\n      title\n      summary\n      class\n      sourceName\n      createdAt\n    }\n    tokenPriceSnapshot {\n      tokenSymbol\n      rates\n      percentDeltas\n      decimals\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateCadence($cadence: ReportCadence!) {\n      updateAccount(cadence: $cadence)\n    }\n  "): (typeof documents)["\n    mutation UpdateCadence($cadence: ReportCadence!) {\n      updateAccount(cadence: $cadence)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateNextReportAt($nextReportAt: DateTime!) {\n      updateAccount(nextReportAt: $nextReportAt)\n    }\n  "): (typeof documents)["\n    mutation UpdateNextReportAt($nextReportAt: DateTime!) {\n      updateAccount(nextReportAt: $nextReportAt)\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query accountNewslettersQuery($limit: Int!) {\n    newsletters(limit: $limit) {\n      id\n      title\n      sourceName\n      createdAt\n    }\n    account {\n      id\n      email\n      moondipInbox\n      confirmed\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n"): (typeof documents)["\n  query accountNewslettersQuery($limit: Int!) {\n    newsletters(limit: $limit) {\n      id\n      title\n      sourceName\n      createdAt\n    }\n    account {\n      id\n      email\n      moondipInbox\n      confirmed\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query accountInvestmentsQuery {\n    accountTokens {\n      token {\n        symbol\n      }\n    }\n  }\n"): (typeof documents)["\n  query accountInvestmentsQuery {\n    accountTokens {\n      token {\n        symbol\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query settingsQuery {\n    account {\n      id\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n"): (typeof documents)["\n  query settingsQuery {\n    account {\n      id\n      cadence\n      timezone\n      nextReportAt\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;