import * as Fathom from "fathom-client";

export const trackEvent = (
  eventName: string,
  options?: Record<string, any>
): void => {
  Fathom.trackEvent(eventName, options);
};

export const blockTracking = (): void => {
  Fathom.blockTrackingForMe();
};
