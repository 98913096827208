import PageHeader from "@/components/PageHeader";
import Card, { CardVariant } from "@/components/Card";
import Copyable from "@/components/Copyable";

const Contact = () => {
  return (
    <section className="px-2">
      <PageHeader>Contact</PageHeader>
      <div className="flex justify-center px-2 pt-4">
        <Card className="bg-amber-200" variant={CardVariant.solid}>
          <p className="mb-4">
            Email us with any questions, feedback, or feature requests. We look
            forward to hearing from you!
          </p>
          <div>
            <Copyable
              value="founders@moondip.xyz"
              className="border-b border-solid border-slate-900 pb-4"
            />
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Contact;
