export const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://api.moondip.xyz";

export const STATIC_APP_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://moondip.xyz";

export const APP_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://app.moondip.xyz";

export const MOONDIP_INBOX_DOMAIN =
  process.env.NODE_ENV === "development"
    ? "@x-test.moondip.xyz"
    : "@x.moondip.xyz";
