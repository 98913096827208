import NewsletterItem from "./NewsletterItem";
import { Newsletter } from "@/gql/graphql";
import DateBlock from "./DateBlock";

type PartialNewsletter = Partial<Newsletter> &
  Pick<
    Newsletter,
    "id" | "title" | "summary" | "class" | "sourceName" | "createdAt"
  >;

const Feed = ({ newsletters }: { newsletters: PartialNewsletter[] }) => {
  const dateLookupTable: { [key: string]: boolean[] } = {};
  const newsletterSegements: {
    date: Date;
    newsletters: PartialNewsletter[];
  }[] = [];

  newsletters.forEach((newsletter) => {
    const dateObj = new Date(newsletter.createdAt);
    const date = `${dateObj.getDate()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()}`;
    if (!dateLookupTable[date]) {
      // dateLookupTable[date] = [];
      /**
       * Has added date to lookup table
       * Has added date segment to newsletterSegements
       */
      dateLookupTable[date] = [true, false];
      newsletterSegements.push({ date: dateObj, newsletters: [] });
    }

    newsletterSegements[newsletterSegements.length - 1].newsletters.push(
      newsletter
    );
    // dateLookupTable[date].push(newsletter);
  });

  return (
    <>
      {newsletterSegements.map((segment, idx) => (
        <section key={segment.date.toDateString()}>
          {segment.date.toDateString() !== new Date().toDateString() ? (
            <DateBlock date={segment.date} />
          ) : (
            <></>
          )}
          {segment.newsletters.map((newsletter) => (
            <NewsletterItem key={newsletter.id} newsletter={newsletter} />
          ))}
        </section>
      ))}
      {/* {newsletters.map((newsletter) => (
        <NewsletterItem key={newsletter.id} newsletter={newsletter} />
      ))} */}
    </>
  );
};

export default Feed;
