import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@/components/Container";
import { graphQLClient, fetchApi } from "@/utils/request";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { graphql } from "@/gql";
import { ReportCadence } from "@/gql/graphql";
import LoadingIcon from "@/components/icons/LoadingIcon";
import { useFlashMessage } from "@/components/FlashMessage";
import PageHeader from "@/components/PageHeader";
import NewsletterDeliverySettings from "./NewsletterDeliverySettings";
import Button from "@/components/Button";
import { STATIC_APP_BASE_URL } from "@/config";
import Card, { CardVariant } from "@/components/Card";

const settingsQueryDocument = graphql(`
  query settingsQuery {
    account {
      id
      cadence
      timezone
      nextReportAt
    }
  }
`);

const Settings = () => {
  const { setFlashMessage } = useFlashMessage();
  const { data, error, isLoading } = useQuery(["account"], async () =>
    graphQLClient.request(settingsQueryDocument)
  );

  useEffect(() => {
    if (error) {
      setFlashMessage((error as Error).message, "error");
    }
  }, [error, setFlashMessage]);

  if (isLoading || !data) {
    return <></>;
  }
  const account = data?.account!;

  return (
    <View
      cadence={account.cadence!}
      timezone="PST"
      nextReportAt={account.nextReportAt!}
    />
  );
};

export default Settings;

interface ViewProps {
  cadence: ReportCadence;
  timezone: string;
  nextReportAt: string;
}

const View = ({ cadence, timezone, nextReportAt }: ViewProps) => {
  return (
    <>
      <PageHeader>Settings</PageHeader>
      <Container className="py-10">
        <div className="flex w-full flex-col items-center">
          <div className="flex flex-col gap-y-16">
            <NewsletterDeliverySection
              cadence={cadence}
              nextReportAt={nextReportAt}
            />
            <UpcomingFeatures />
            <Logout />
          </div>
        </div>
      </Container>
    </>
  );
};

const SectionHeader = ({ children }: { children: React.ReactNode }) => {
  return <h2 className="font-semibold uppercase">{children}</h2>;
};

const Section = ({ children }: { children: React.ReactNode }) => {
  return <section className="flex flex-col gap-y-4">{children}</section>;
};

const NewsletterDeliverySection = ({
  cadence,
  nextReportAt,
}: {
  cadence: ReportCadence;
  nextReportAt: string;
}) => {
  return (
    <Section>
      <SectionHeader>Newsletter Delivery</SectionHeader>
      <NewsletterDeliverySettings
        cadence={cadence}
        nextReportAt={nextReportAt}
      />
    </Section>
  );
};

const UpcomingFeatures = () => {
  return (
    <Section>
      <SectionHeader>Upcoming Features</SectionHeader>
      <Card className="text-sm" variant={CardVariant.dashed}>
        <p>⁕ Crypto and stock prices</p>
        <p>⁕ Calendar</p>
        <p>⁕ Weather</p>
      </Card>
    </Section>
  );
};

const Logout = () => {
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();

  const handleClick = async () => {
    try {
      const res = await fetchApi("logout", { method: "POST" });
      if (res.ok) {
        window.location.href = STATIC_APP_BASE_URL;
      } else {
        setFlashMessage("Failed to sign out.", "error");
      }
    } catch (err) {
      setFlashMessage((err as Error).message, "error");
    }
  };

  return (
    <Button onClick={handleClick} variant="outline" size="small">
      Sign Out
    </Button>
  );
};
