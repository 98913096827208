import { Square2StackIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Button, { VariantStyles } from "@/components/Button";
import { useFlashMessage } from "@/components/FlashMessage";

interface Props {
  className?: string;
  value: string;
  variant?: keyof VariantStyles;
}

const Copyable = ({ className, value, variant = "outline" }: Props) => {
  const { setFlashMessage } = useFlashMessage();

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    setFlashMessage("Copied to clipboard.", "success");
  };

  return (
    <div
      className={clsx("inline-flex flex-nowrap items-center gap-4", className)}
    >
      <span className="h-max w-max overflow-hidden overflow-ellipsis whitespace-nowrap">
        <span className="w-max">{value}</span>
      </span>
      <Button variant={variant} size="small" onClick={handleClick}>
        <Square2StackIcon className="h-4 w-4" />
        Copy
      </Button>
    </div>
  );
};

export default Copyable;
