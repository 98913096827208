import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface Option {
  label: string;
  value: string | number;
}

export enum DropdownVariant {
  outline = "outline",
  text = "text",
}

interface Props {
  className?: string;
  variant?: DropdownVariant;
  options: Option[];
  value: string | number;
  position?: "left" | "right";
  onSelect: (option: any) => void;
}

const Dropdown = ({
  className,
  variant = DropdownVariant.outline,
  options,
  value,
  position = "right",
  onSelect,
}: Props) => {
  const label = options.find((option) => option.value === value)?.label;

  return (
    <Menu
      as="div"
      className={clsx(
        "relative inline-block text-left text-gray-900",
        className
      )}
    >
      <div>
        <Menu.Button
          className={clsx("cpaitalize inline-flex w-full text-sm ", {
            "justify-between bg-white px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50":
              variant === DropdownVariant.outline,
            "underline hover:text-gray-700": variant === DropdownVariant.text,
          })}
        >
          {label}
          <ChevronDownIcon
            className={clsx("h-5 w-5 ", {
              "-mr-1 text-gray-400": variant === DropdownVariant.outline,
              "mt-0.5 text-slate-600": variant === DropdownVariant.text,
            })}
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            "absolute z-10 mt-2 w-56 origin-top-right cursor-pointer bg-white ring-1 ring-black focus:outline-none",
            position === "left" ? "left-0" : "right-0"
          )}
        >
          <div className="max-h-60 overflow-y-scroll">
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <div
                    onClick={() => onSelect(option.value)}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm capitalize"
                    )}
                  >
                    {option.label}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
