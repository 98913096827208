import React, { useState, useEffect } from "react";
import clsx from "clsx";
import debounce from "debounce";
import { Account, Newsletter } from "@/gql/graphql";
import { API_BASE_URL } from "@/config";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { graphql } from "@/gql";
import { graphQLClient } from "@/utils/request";
import { useFlashMessage } from "@/components/FlashMessage";
import { useQuery } from "@tanstack/react-query";
import Button from "@/components/Button";
import Card, { CardVariant } from "@/components/Card";
import LoadingIcon from "@/components/icons/LoadingIcon";
import NewsletterCard from "@/components/NewsletterCard";
import CreateInboxForm from "./CreateInboxForm";
import Settings from "./Settings";
import ConfirmEmailNotice from "@/components/ConfirmEmailNotice";
import PageHeader from "@/components/PageHeader";

type PartialAccount = Partial<Account> &
  Pick<
    Account,
    | "id"
    | "email"
    | "moondipInbox"
    | "confirmed"
    | "cadence"
    | "timezone"
    | "nextReportAt"
  >;

type PartialNewsletter = Partial<Newsletter> &
  Pick<Newsletter, "id" | "title" | "sourceName" | "createdAt">;

const InboxQueryDocument = graphql(`
  query accountNewslettersQuery($limit: Int!) {
    newsletters(limit: $limit) {
      id
      title
      sourceName
      createdAt
    }
    account {
      id
      email
      moondipInbox
      confirmed
      cadence
      timezone
      nextReportAt
    }
  }
`);

const LIMIT_INCREMENT = 20;

export default function Inbox() {
  const [newsletterLimit, setNewsletterLimit] = useState(LIMIT_INCREMENT);

  const { data, error, isFetching, refetch } = useQuery(
    ["inbox", newsletterLimit],
    async () => {
      return graphQLClient.request(InboxQueryDocument, {
        limit: newsletterLimit,
      });
    },
    {
      refetchOnWindowFocus: "always",
      keepPreviousData: true,
    }
  );

  /* Manage Newsletter Query Limit Based on Scroll Position */
  useEffect(() => {
    const handleScroll = debounce(
      () => {
        const { scrollHeight, scrollTop, clientHeight } =
          document.documentElement;
        const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

        if (scrollPercentage > 0.7) {
          if (data?.newsletters.length === newsletterLimit) {
            setNewsletterLimit((prev) => prev + LIMIT_INCREMENT);
          }
        }
      },
      10,
      { immediate: true }
    );

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data?.newsletters, newsletterLimit]);

  /* Manage Refetching Based On Window Focus */
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;

    const onFocus = () => {
      intervalId = setInterval(() => {
        refetch();
      }, 5000);
    };

    const onBlur = () => {
      clearInterval(intervalId);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      clearInterval(intervalId);
    };
  }, [refetch]);

  if (error) {
    // TODO display error message
    return <></>;
  }

  const {
    account = {} as PartialAccount,
    newsletters = [] as PartialNewsletter[],
  } = data || {};

  const viewComponents: [string, () => JSX.Element | boolean][] = [
    [
      "delivery-settings",
      () =>
        !!account?.moondipInbox &&
        newsletters.length > 0 && (
          <div className="px-2 sm:px-0">
            <Settings
              cadence={account?.cadence}
              nextReportAt={account?.nextReportAt}
            />
          </div>
        ),
    ],
    [
      "confirm-email-notice",
      () =>
        !!account?.email &&
        !!account?.moondipInbox &&
        newsletters.length > 0 &&
        !account?.confirmed && (
          <div className="px-2 sm:px-0">
            <ConfirmEmailNotice email={account?.email} />
          </div>
        ),
    ],
    ["header", () => <PageHeader>Inbox</PageHeader>],
    [
      "inbox",
      () =>
        newsletters.length > 0 && (
          <>
            {newsletters?.map((newsletter: any) => (
              <NewsletterCard key={newsletter.id} {...newsletter} />
            ))}
            <div className="flex justify-center py-4">
              <LoadingIcon
                className={clsx("ml-2 mt-1 h-3 w-3", {
                  invisible:
                    !isFetching || newsletters.length < LIMIT_INCREMENT,
                })}
              />
            </div>
          </>
        ),
    ],
    [
      "create-inbox-form",
      () =>
        !!account.email &&
        !account.moondipInbox && (
          <CreateInboxForm
            email={account.email}
            onSuccess={() => {
              refetch();
            }}
          />
        ),
    ],
    [
      "zero-state",
      () =>
        !!account.moondipInbox &&
        !newsletters.length && (
          <ZeroState
            email={account.email}
            moondipInbox={account.moondipInbox}
          />
        ),
    ],
  ];

  return viewComponents.map(([id, renderComponent]) => (
    <React.Fragment key={id}>{renderComponent()}</React.Fragment>
  ));
}

interface ZeroStateProps {
  moondipInbox: string;
  email: string;
}

const ZeroState = ({ moondipInbox, email }: ZeroStateProps) => (
  <div className="pt-10 sm:w-4/5">
    <h2 className="mb-4 font-semibold capitalize">
      Welcome to your MoonDip inbox
    </h2>
    {/* <div className="mt-8 flex flex-col items-center gap-y-6"> */}
    <Card className="mb-8" variant={CardVariant.dashed}>
      <ol className="text-l list-outside list-decimal space-y-4 pl-5">
        <li>
          Use <span className="underline">{moondipInbox}</span> to subscribe to
          newsletters.
        </li>
        <li>Confirmation emails will show up here.</li>
        <li>
          Your digests will be sent to{" "}
          <span className="underline">{email}</span>.
        </li>
      </ol>
    </Card>
    <Button className="w-full" href="/discover">
      Discover Newsletters
      <ArrowRightIcon className="ml-2 inline h-5 w-5" />
    </Button>
    {/* </div> */}
  </div>
);
