import { Link } from "react-router-dom";
import clsx from "clsx";
import LoadingIcon from "./icons/LoadingIcon";

interface BaseStyles {
  solid: string;
  outline: string;
  text: string;
}

interface VariantStyle {
  slate: string;
  blue?: string;
  white: string;
  amber?: string;
  rose?: string;
  custom?: string;
}

const baseStyles: BaseStyles = {
  solid:
    "group inline-flex items-center justify-center font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-gray-200 disabled:text-slate-500 dark:disabled:text-slate-800 disabled:border-slate-200 dark:disabled:text-slate-500 dark:disabled:hover:bg-gray-400 dark:disabled:bg-gray-400 disabled:shadow-none shadow-md",
  outline:
    "group inline-flex ring-1 items-center justify-center focus:outline-none font-semibold disabled:shadow-none disabled:bg-gray-200 disabled:ring-gray-400 shadow-lg",
  text: "group inline-flex items-center justify-center font-semibold focus:outline-none",
};

export interface VariantStyles {
  solid: VariantStyle;
  outline: VariantStyle;
  text: VariantStyle;
}

const variantStyles: VariantStyles = {
  solid: {
    slate:
      "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
    // dark:bg-white dark:text-slate-900  dark:hover:bg-blue-50 dark:hover:text-slate-900  dark:active:bg-blue-200 dark:active:text-slate-600 dark:focus-visible:outline-white
    blue: "bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600",
    white:
      "bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white",
    amber:
      "bg-amber-400 text-white hover:bg-amber-300 active:bg-amber-600 active:text-amber-100 focus-visible:outline-amber-600 dark:bg-amber-500 dark:text-gray-100  dark:hover:bg-amber-400 dark:hover:text-gray-50  dark:active:bg-amber-300 dark:active:text-white dark:focus-visible:outline-gray-50",
    rose: "bg-rose-400 text-white hover:bg-rose-300 active:bg-rose-600 active:text-rose-100 focus-visible:outline-rose-600 dark:bg-rose-500 dark:text-gray-100  dark:hover:bg-rose-400 dark:hover:text-gray-50  dark:active:bg-rose-300 dark:active:text-white dark:focus-visible:outline-gray-50",
  },
  outline: {
    slate:
      "ring-slate-500 text-slate-700 hover:text-white hover:bg-black hover:ring-black active:bg-slate-700 active:text-slate-100 focus-visible:outline-blue-600 focus-visible:ring-slate-300",
    // dark:text-white dark:hover:text-slate-800 dark:hover:bg-gray-400
    white:
      "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
    blue: "ring-blue-500 text-blue-600 hover:ring-blue-600 active:ring-blue-700 focus-visible:outline-blue-600",
    custom: "",
  },
  text: {
    slate:
      "text-slate-900 hover:text-slate-700 active:text-slate-600 focus-visible:outline-blue-600",
    white:
      "text-white hover:text-slate-100 active:text-slate-200 focus-visible:outline-white",
    custom: "",
  },
};

interface SizeStyles {
  small: string;
  base: string;
}

const sizeStyles = {
  small: "py-1 px-2 text-xs",
  base: "py-4 px-8 text-base",
};

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  color?: keyof VariantStyle;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: keyof SizeStyles;
  target?: string;
  type?: "button" | "submit" | "reset";
  variant?: keyof VariantStyles;
  props?: any;
}

const Button = ({
  children,
  className,
  color = "slate",
  disabled,
  href,
  loading,
  onClick,
  size = "base",
  target,
  type,
  variant = "solid",
  ...props
}: ButtonProps) => {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    sizeStyles[size],
    className
  );

  if (href) {
    return (
      <Link
        to={href}
        target={target}
        className={clsx(className, {
          "bg-gray-200 shadow-none ring-gray-400": disabled || loading,
        })}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      {loading && (
        <span className="relative right-2">
          <LoadingIcon className="h-4 w-4" />
        </span>
      )}
      {children}
    </button>
  );
};

export default Button;
