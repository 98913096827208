import { GraphQLClient } from "graphql-request";
import { API_BASE_URL, STATIC_APP_BASE_URL } from "@/config";

export const graphQLClient = new GraphQLClient(`${API_BASE_URL}/graphql`, {
  credentials: "include",
  mode: "cors",
  responseMiddleware: (res) => {
    if (
      (!(res instanceof Error) && res.status === 401) || // 401 is the HTTP status code for Unauthorized
      (res instanceof Error && res.message.includes("Unauthorized"))
    ) {
      window.location.href = `${STATIC_APP_BASE_URL}/login`;
    }
    return res;
  },
});

export const fetchApi = async (endpoint: string, options = {}) => {
  return fetch(`${API_BASE_URL}/${endpoint}`, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    ...options,
  });
};
