import "./App.css";
import Dashboard from "./components/Dashboard";
import Inbox from "./pages/Inbox";
import Discover, { discoverLoader } from "./pages/Discover";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FlashMessageProvider } from "./components/FlashMessage";
import { AppContextProvider } from "./components/AppContext";
import Settings from "./pages/Settings/";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    id: "dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "inbox",
        element: <Inbox />,
      },
      {
        path: "discover",
        element: <Discover />,
        loader: discoverLoader,
      },
      {
        path: "settings",
        id: "settings",
        element: <Settings />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return (
    <AppContextProvider>
      <FlashMessageProvider>
        <RouterProvider router={router} />
      </FlashMessageProvider>
    </AppContextProvider>
  );
}

export default App;
