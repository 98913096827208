import { Newsletter, NewsletterClass } from "@/gql/graphql";
import { formatDate } from "@/utils/formatters";
import { STATIC_APP_BASE_URL } from "@/config";
import FeedCard from "./FeedCard";

export type PartialNewsletter = Partial<Newsletter> &
  Pick<
    Newsletter,
    "id" | "title" | "summary" | "class" | "sourceName" | "createdAt"
  >;

export default function NewsletterItem({
  newsletter,
}: {
  newsletter: PartialNewsletter;
}) {
  const renderNewsletter = () => {
    switch (newsletter.class) {
      case NewsletterClass.MultiTopic:
        return <MultiTopic key={newsletter.id} newsletter={newsletter} />;
      case NewsletterClass.SingleTopic:
        return <SingleTopic key={newsletter.id} newsletter={newsletter} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      {renderNewsletter()}
    </div>
  );
}

interface ItemProps {
  newsletter: PartialNewsletter;
}

type MultiTopicSummary = {
  primary_topics: Topic[];
  secondaryTopics: string[];
};

type Topic = {
  topic: string;
  summary: string;
};

const MultiTopic = ({ newsletter }: ItemProps) => {
  let summary_raw = newsletter.summary || "";

  let summary: MultiTopicSummary | null = null;
  if (summary_raw.length > 0) {
    summary = JSON.parse(summary_raw);
  }

  return (
    <NewsletterCard
      id={newsletter.id}
      title={newsletter.title}
      sourceName={newsletter.sourceName}
      createdAt={newsletter.createdAt}
      key={newsletter.id}
    >
      <ul className="space-y-4 pt-2">
        {summary?.primary_topics.map((topic, idx) => (
          <li key={`${newsletter.id}-${idx}`}>
            <h3 className="mb-1 font-semibold">{topic.topic}</h3>
            <p className="text-sm  text-slate-800">{topic.summary}</p>
          </li>
        ))}
      </ul>
    </NewsletterCard>
  );
};

const SingleTopic = ({ newsletter }: ItemProps) => {
  return (
    <NewsletterCard
      id={newsletter.id}
      title={newsletter.title}
      sourceName={newsletter.sourceName}
      createdAt={newsletter.createdAt}
    >
      <div className="flex flex-col gap-y-2">
        {newsletter.summary
          ?.split("\n")
          .filter((para) => para)
          .map((paragraph, idx) => (
            <p className="text- text-slate-800" key={`${newsletter.id}-${idx}`}>
              {paragraph}
            </p>
          ))}
      </div>
    </NewsletterCard>
  );
};

interface NewsletterCardProps {
  id: string;
  title: string;
  sourceName: string;
  createdAt: string;
  children: React.ReactNode;
}

const NewsletterCard = ({
  id,
  title,
  sourceName,
  createdAt,
  children,
}: NewsletterCardProps) => {
  return (
    <FeedCard
      to={`${STATIC_APP_BASE_URL}/newsletter/${id}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className=" flex items-end justify-between text-xs font-semibold uppercase text-gray-600">
        <h2>{sourceName}</h2>
        <p>{formatDate(createdAt)}</p>
      </div>
      <div className="flex flex-col gap-y-1 pt-3">
        <h4 className="font-semibold text-slate-800">{title}</h4>
        <div className="text-[15px] text-slate-800">{children}</div>
      </div>
    </FeedCard>
  );
};
