import { useEffect } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import clsx from "clsx";
import * as Fathom from "fathom-client";
import { graphQLClient } from "@/utils/request";
import { graphql } from "@/gql";
import {
  InboxIcon,
  MagnifyingGlassIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import Logo from "./icons/Logo";
import Button from "./Button";

const dashboardQueryDocument = graphql(`
  query dashboardQuery {
    account {
      id
      moondipInbox
    }
  }
`);

export const dashboardLoader = async () => {
  try {
    const data = await graphQLClient.request(dashboardQueryDocument);
    return { account: data.account, error: null };
  } catch (error) {
    return { account: undefined, error };
  }
};

type NavItem = {
  name: string | JSX.Element;
  href: string;
  // icon: React.ElementType;
  target?: string;
  disabled?: boolean;
};

const navigation: NavItem[] = [
  {
    name: "Home",
    href: "/home",
    // icon: HomeIcon
  },
  {
    name: "Discover",
    href: "/discover",
    // icon: MagnifyingGlassIcon,
  },
  {
    name: "Inbox",
    href: "/inbox",
    // icon: InboxIcon
  },
  {
    name: "Settings",
    href: "/settings",
    // icon: HomeIcon
  },
];

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Fathom.load(process.env.NEXT_PUBLIC_FATHOM_SITE_ID || "", {
      includedDomains: ["app.moondip.xyz"],
    });
  }, []);

  useEffect(() => {
    Fathom.trackPageview();

    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  return (
    <div className="flex h-full min-h-screen w-screen flex-col items-center bg-neutral-100 pt-8 sm:px-4 md:px-0">
      <div className="w-full max-w-screen-md">
        <div className="px-2">
          <div className="flex w-full items-center justify-between border-b border-black pb-2">
            <Link to="/home" className="flex items-center gap-x-2 font-bold">
              <Logo className="h-6 w-6" />
              <span>MOONDIP</span>
            </Link>
            <Button
              className="uppercase underline"
              variant="text"
              size="small"
              href="contact"
            >
              Contact
            </Button>
          </div>
          <nav className="grid w-full grid-flow-row grid-cols-4 gap-4 pt-4">
            {navigation.map((item) => (
              <Button
                className={clsx("gap-x-1 font-semibold leading-6", {
                  "bg-violet-100": location.pathname === item.href,
                })}
                variant="outline"
                size="small"
                href={item.href}
                key={item.href}
                disabled={item.disabled}
              >
                {/* <item.icon className="h-4 w-4 shrink-0" aria-hidden="true" /> */}
                {item.name}
              </Button>
            ))}
          </nav>
        </div>
        <main className="w-full pb-10 pt-6 sm:px-2">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
