export const formatDate = (date: string) => {
  const currentDate = new Date();
  const providedDate = new Date(date);

  if (
    currentDate.getDate() === providedDate.getDate() &&
    currentDate.getMonth() === providedDate.getMonth() &&
    currentDate.getFullYear() === providedDate.getFullYear()
  ) {
    return providedDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return providedDate.toLocaleDateString([], {
      month: "short",
      day: "2-digit",
    });
  }
};
