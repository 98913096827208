import clsx from "clsx";

const formClasses =
  "block w-full appearance-none rounded-md border dark:bg-slate-950 dark:text-gray-50 border-gray-200 bg-gray-50 px-3 py-2 text-slate-800 placeholder-gray-400 dark:text-slate-300 dark:bg-slate-950 dark:border-white dark:focus:bg-slate-950 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm";

interface LabelProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

interface TextFieldProps {
  id: string;
  label: string;
  type?: string;
  name: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  register?: any;
  props?: any;
  required?: boolean;
}

interface SelectFieldProps {
  id: string;
  label: string;
  className?: string;
  props: any;
}

export function Label({ id, children, className }: LabelProps) {
  const _className = clsx(
    "block text-sm font-medium text-slate-800",
    className
  );

  return (
    <label htmlFor={id} className={_className}>
      {children}
    </label>
  );
}

export function TextField({
  id,
  label,
  type = "text",
  className = "",
  register,
  ...props
}: TextFieldProps) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={id}
        type={type}
        {...props}
        {...register} // of react-hook-form
        className={formClasses}
      />
    </div>
  );
}

export function SelectField({
  id,
  label,
  className = "",
  ...props
}: SelectFieldProps) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, "pr-8")} />
    </div>
  );
}
